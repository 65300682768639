<script setup lang="ts">
const menuItems = useNavigationMenu().navigationMenu()
const about = useNavigationMenu().navigationAbout()
const active = ref(false)
const currentPath = ref('')
const activeParentMenu = computed(() => currentPath.value)
const route = useRoute()
function toggleMenu() {
  setTimeout(() => {
    active.value = !active
  }, 200)
}
watch(
  () => route.path,
  (current) => {
    currentPath.value = current
  }
)
</script>

<template>
  <div class="header" :class="active ? 'active' : ''">
    <NuxtLink to="/" @click="toggleMenu()">
      <img class="header__logo" src="~/assets/_logo-horizontal-GREEN.svg" alt="Logotyp" />
    </NuxtLink>
    <div
      role="button"
      tabIndex="0"
      aria-label="Öppna navigeringsmeny"
      :aria-expanded="active"
      @click="active = !active"
      @keydown.enter="active = !active"
      @keydown.space="active = !active"
    >
      <AppIcon v-if="active" icon="XMarkIcon" class="header__menu" />
      <AppIcon v-else icon="Bars3Icon" class="header__menu" />
    </div>
    <div class="header__navigation">
      <div class="header__list">
        <NuxtLink
          v-for="(item, i) of menuItems"
          :key="item.label"
          :to="item.to"
          class="header__list--item animation"
          :class="activeParentMenu.includes(item.to) && 'router-link-active'"
          :style="`--animation-order: ${i + 1}`"
          @click="toggleMenu()"
        >
          {{ item.label }}
        </NuxtLink>
      </div>
      <NuxtLink
        :to="about.to"
        class="header__list--item animation"
        :style="`--animation-order: ${menuItems.length + 1}`"
        @click="toggleMenu()"
      >
        {{ about.label }}
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'App.scss';
.beta {
  position: relative;
  &:after {
    content: 'BETA';
    display: block;
    position: absolute;
    top: 8px;
    left: 100px;
    padding: 2px 8px;
    background: #fcd107;
    border-radius: 8px;
    font-size: 10px;
    font-weight: 500;
    color: #000;
  }
}
.header {
  background: $white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 0 1.5rem;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  height: $topbarHeight;

  &__navigation {
    display: none;

    @include desktop-up() {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__list {
    &--item {
      padding: 0 1rem;
    }
  }

  &__menu {
    display: none;
    width: 30px;
    margin-top: calc(4rem / 2 - 30px);
    color: $dark;

    @include desktop-down() {
      float: right;
      display: flex;
      flex-direction: column;
    }
  }

  &__logo {
    height: 4rem;
    padding: 1rem 1rem 1rem 0;
  }

  &.active {
    display: block;
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;

    .header__menu {
      margin-top: calc(4rem / 2 - 12px);
      position: absolute;
      top: 0;
      right: 1.5rem;
    }

    .header__navigation {
      display: flex;
      background: $white;
      flex-direction: column;
      text-align: center;

      @include desktop-down() {
        padding-bottom: 1rem;
      }
    }

    .header__list {
      display: flex;
      flex-direction: column;

      @include desktop-up() {
        flex-direction: row;
      }
    }

    .header__list--item {
      padding: 1rem;
      font-size: 1.25rem;
      font-weight: 500;
      text-align: center;
    }

    .animation {
      animation-name: animateIn;
      animation-duration: 350ms;
      animation-delay: calc(var(--animation-order) * 100ms);
      animation-fill-mode: both;
      animation-timing-function: ease-out;
    }
  }
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }

  100% {
    opacity: 1;
  }
}

a {
  color: $navigation;
}
</style>
