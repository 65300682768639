export function useNavigationMenu() {
  const navigationMenu = () => {
    return [
      {
        label: 'Samarbeta',
        to: '/samarbeta',
        // items: [
        //   { label: 'Skapa inlägg', to: '/samarbeta/skapa-inlagg' },
        // ]
      },
      {
        label: 'Utforska län/kommun',
        to: '/utforska-en-plats',
      },
      {
        label: 'Kartor & statistik',
        to: '/kartor-och-statistik',
      },
      // {
      //   label: 'Skapa din karta',
      //   to: '/skapa-karta',
      // },
      {
        label: 'Kunskapsbank',
        to: '/kunskapsbank',
      },
    ]
  }

  const navigationFooter = () => {
    return [
      {
        label: 'Behandling av personuppgifter',
        to: '/villkor',
      },
      {
        label: 'Definitioner',
        to: '/definitioner',
      },
      // {
      //   label: 'Inställningar för kakor',
      //   to: '/installningar-kakor',
      // },
    ]
  }

  const navigationAbout = () => {
    return {
      label: 'Om Drivmedla',
      to: '/om-drivmedla',
    }
  }

  return { navigationMenu, navigationFooter, navigationAbout }
}
